<template>
  <zy-dialog v-if="visible" :visible="visible" width="1152px" class="quality-dailog" :title="title" @close="closeDialog">
    <div class="flex-box" style="padding-bottom: 20px;" v-if="!isHidden">
      <div class="jxyh-flex-h" style="width: 149px;height: 220px;margin-bottom: 20px;" v-for="(item, i) in buildList" :key="i" @click="selectBuild(item)">
        <zy-build :width="100" :floors="10" @select="selectFloor"></zy-build>
        <div class="build-name">{{ item.buildingName }}</div>
        <div>({{ item.floorCount }}层)</div>
      </div>
    </div>
    <div class="flex-box" style="padding: 0;justify-content: space-between;" v-else>
      <div class="jxyh-flex-h" style="gap: 10px;">
        <div @click="setLevel(i)" :class="{'active':i == curLevel}" class="block" v-for="i in Number(floorLevel)" :key="i">
          {{(i-1)*20+1}}~{{ (i-1)*20+(i == floorLevel?currentBuild.floorCount % 20:20) }}层
        </div>
      </div>
      <zy-build :key="build_key" ref="leftBuild" :width="165" :disabled="false" :floors="floors" @select="selectFloor"></zy-build>
      <div style="width: calc(100% - 350px)">
        <div class="back" @click="back">
          <span style="color: #66bbf9">
            <i class="el-icon-back"></i>返回
            <div class="slider"></div>
          </span>
          <span>{{ currentBuild.buildingName }}({{ currentFloor }}层)</span>
        </div>
        <zy-table :pagination="true" :height="510" :data="listTable">
          <slot></slot>
        </zy-table>
        <slot name="page"></slot>
      </div>
    </div>
  </zy-dialog>
</template>

<script>
import components from '@/view/jxyh/components'
import { getBuildList, getFloorList } from '@/jxyh/api/quality'
export default {
  ...components(['ZyTable', 'ZyDialog', 'ZyBuild']),
  props: {
    title: {
      type: String,
      default: ''
    },
    listTable:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      buildList: [],
      floorList: [],
      currentBuild: {},
      currentFloor: '',
      isHidden: false,
      visible: false,
      floorLevel: 1,
      curLevel:1,
      floors: [],
      build_key: null,
    }
  },
  mounted(){
    this.getBuildList()
  },
  methods: {
    getBuildList(){
      getBuildList().then((res) => {
        this.buildList = res.data
      })
    },
    getOneFloorList(item) {
      getFloorList({ buildingId: item.buildingId }).then((res) => {
        this.floorList = res.data
        this.floorLevel = Math.ceil(item.floorCount / 20)
        if(item.floorCount > 20){
          this.floors = this.floorList.slice((this.curLevel-1) * 20,(this.curLevel-1) * 20 + 20).map(p => p.floorName)
          // this.floors = Array.from({length:20}, (v,k) => k + 1)
        }else{
          this.floors = this.floorList.slice(0,item.floorCount).map(p => p.floorName)
        }
        this.isHidden = true
        this.$nextTick(()=>{
          this.$refs.leftBuild.clickFloor(0)
        })
      })
    },
    open() {
      this.visible = true
    },
    closeDialog() {
      this.visible = false
      this.isHidden = false
      this.currentFloor = ''
      this.floorLevel = 1
      this.curLevel = 1
      this.floors = []
    },
    selectFloor(val) {
      let floor = (this.curLevel-1)*20 + val
      this.currentFloor = this.floors[val]
      this.$emit('select',this.floorList[floor])
    },
    selectBuild(item) {
      this.currentBuild = item
      this.getOneFloorList(item)
    },
    back() {
      this.isHidden = false
      this.currentFloor = ''
      this.floorLevel = 1
      this.curLevel = 1
      this.floors = []
    },
    setLevel(i){
      this.curLevel = i
      let length = 20
      if(this.curLevel == this.floorLevel) length = this.currentBuild.floorCount % 20
      this.floors = this.floorList.slice((this.curLevel-1) * 20,(this.curLevel-1) * 20 + length).map(p => p.floorName)
      // this.floors = Array.from({length}, (v,k) => (i-1)*20 + k + 1)
      this.build_key = this.$system.id(16)
      this.$nextTick(() =>{
        this.$refs.leftBuild.clickFloor(0)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.quality-dailog {
  .flex-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
    .build-name{
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
    }
    .back {
      cursor: pointer;
      line-height: 24px;
      margin-bottom: 10px;
      font-size: 16px;
      .slider {
        display: inline-block;
        width: 1px;
        height: 16px;
        background: #ffffff;
        margin: 0 10px;
        vertical-align: middle;
      }
    }
  /deep/ .block {
    width: 70px;
    font-size: 12px;
    padding: 9px;
    border: 1px solid rgba(255,255,255,0.1);
    background: transparent;
    cursor: pointer;
    text-align: center;
    &.active{
      background: linear-gradient( 143deg, rgba(0,233,255,0.46) 0%,rgba(28,47,99,0)  100%);
      border-image: linear-gradient(135deg, rgba(51, 216, 250, 1), rgba(24, 123, 205, 0)) 1 1;
    }
  }
  }
}
</style>